import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { serializeFormToFormData, applyActiveFieldClass, applyInputFileEnhancements } from "common/utils.js";
import axios from "axios";
import autosize from "autosize";

export default class Form extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.shape({
			type: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			alias: PropTypes.string.isRequired,
			body: PropTypes.string,
			summary: PropTypes.string,
			successMessage: PropTypes.string.isRequired,
			formId: PropTypes.oneOf(["contact_us", "communications_contact", "internal_market_complaint"]).isRequired,
			formMetadata: PropTypes.shape({
				title: PropTypes.string.isRequired,
				fields: PropTypes.arrayOf(
					PropTypes.shape({
						name: PropTypes.string.isRequired,
						type: PropTypes.oneOf(["text", "textarea", "radio", "file"]).isRequired,
						label: PropTypes.string.isRequired,
						maxlength: PropTypes.number,
						required: PropTypes.bool.isRequired,
						accept: PropTypes.arrayOf(PropTypes.string),
						options: PropTypes.arrayOf(PropTypes.string)
					}).isRequired
				),
				uploadMaxSize: PropTypes.number
			}).isRequired
		}).isRequired
	};

	getInitialState = () => ({
		submissionStatus: undefined, // ['warning-uploadMaxSize', 'pending', 'success', 'error']
		acceptedDisclaimer: undefined
	});

	state = this.getInitialState();

	shouldFormBeDisabled = () => ["warning-uploadMaxSize", "pending"].includes(this.state.submissionStatus);

	shouldFormHaveAnimatedLabels = () => this.props.node.formId !== "internal_market_complaint";

	enhanceDomForm = () => {
		// Form may not have been rendered yet (corner case: switching from a submitted form to another form).
		if (!this.formDomNode) return;

		if (this.shouldFormHaveAnimatedLabels()) {
			// Toggle CSS animation related class on focus/blur.
			applyActiveFieldClass(this.formDomNode);
		}

		// Apply dom manipulations to style input[type=file]
		applyInputFileEnhancements(this.formDomNode, this.props.t);

		// Apply autosize to auto grow textareas.
		this.formDomNode.querySelectorAll("textarea[name]").forEach(textarea => {
			autosize(textarea);
		});
	};

	componentDidMount() {
		this.enhanceDomForm();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.node.alias !== this.props.node.alias) {
			this.setState(this.getInitialState(), this.enhanceDomForm);
		}
		if (prevState.acceptedDisclaimer !== this.state.acceptedDisclaimer) {
			this.enhanceDomForm();
		}
	}

	onSubmit = e => {
		e.preventDefault();
		const formData = serializeFormToFormData(this.formDomNode, true);

		this.setState({ submissionStatus: "pending" });
		return axios
			.post(`api/form?url=${encodeURIComponent(this.props.node.alias)}`, formData, {
				timeout: 0 // Do not timeout because the user may be uploading files.
			})
			.then(() => {
				this.setState({ submissionStatus: "success" });
				this.formDomNode.reset();
			})
			.catch(() => {
				this.setState({ submissionStatus: "error" });
			});
	};

	/**
	 * Calculates total post size and warns user if it has exceeded what the server
	 * is wiling to accept.
	 */
	checkFormPostSize = () => {
		let total = 0;
		this.formDomNode.querySelectorAll("input[name][type=file]").forEach(field => {
			Array.from(field.files).forEach(file => {
				total += file.size;
			});
		});
		if (total >= this.props.node.formMetadata.uploadMaxSize) {
			this.setState({ submissionStatus: "warning-uploadMaxSize" });
		} else {
			// Reset status only if previous status was the uploadMaxSize warning.
			if (this.state.submissionStatus === "warning-uploadMaxSize") {
				this.setState({ submissionStatus: undefined });
			}
		}
	};

	render() {
		const { t, node } = this.props;
		const { submissionStatus } = this.state;

		const renderField = field => (
			<div key={field.name} className={`field-type-${field.type} field-name-${field.name}`}>
				<label htmlFor={`field-name-${field.name}`} className={field.required ? "required" : undefined}>
					{field.label}
				</label>
				{field.type === "text" && (
					<input
						id={`field-name-${field.name}`}
						type="text"
						maxLength={field.maxlength}
						name={field.name}
						required={field.required}
					/>
				)}
				{field.type === "textarea" && (
					<textarea
						id={`field-name-${field.name}`}
						maxLength={field.maxlength}
						name={field.name}
						required={field.required}
						rows="1" // in order to have the same height as the inputs
					/>
				)}
				{field.type === "radio" &&
					field.options.map((option, index) => (
						<label key={index} className="radio-option">
							<input type="radio" name={field.name} required={field.required} value={option} />
							<span>{option}</span>
						</label>
					))}
				{field.type === "file" && (
					<input
						id={`field-name-${field.name}`}
						type="file"
						name={field.name}
						required={field.required}
						className="button hollow small"
						accept={field.accept.join(",")}
						multiple
						onChange={this.checkFormPostSize}
					/>
				)}
			</div>
		);

		const renderForm = fields => (
			<form
				method="post"
				className={node.formId}
				onSubmit={this.onSubmit}
				ref={node => (this.formDomNode = node)}
				onReset={() => window.setTimeout(() => this.checkFormPostSize(), 0)} // setTimeout to make sure this runs after the input files have been cleared by the browser
			>
				<h2>{node.formMetadata.title}</h2>
				<div className="fields">
					{fields}
					<div className="field-type-checkbox field-name-gdpr">
						<input id="field-name-gdpr" type="checkbox" required />
						<label htmlFor="field-name-gdpr">
							{t("Form.gdpr.title")}
							<br />
							<small dangerouslySetInnerHTML={{ __html: t("Form.gdpr.details_html") }} />
						</label>
					</div>
				</div>
				{submissionStatus === "warning-uploadMaxSize" && (
					<div className="message warning">
						<p>
							{t("Form.submissionStatus.warning-uploadMaxSize", {
								uploadMaxSize: node.formMetadata.uploadMaxSize
							})}
						</p>
					</div>
				)}
				{submissionStatus === "pending" && (
					<div className="message pending">
						<p>{t("Form.submissionStatus.pending")}</p>
					</div>
				)}
				{submissionStatus === "success" && (
					<div className="message success">
						<p>{node.successMessage}</p>
					</div>
				)}
				{submissionStatus === "error" && (
					<div className="message error">
						<p>{t("Form.submissionStatus.error")}</p>
					</div>
				)}
				<div className="actions">
					<input className="button small hollow" type="reset" value={t("reset")} />
					<button className="button small" type="submit" disabled={this.shouldFormBeDisabled()}>
						{t("Form.submit")}
					</button>
				</div>
			</form>
		);

		const renderContactUsForm = () => renderForm(node.formMetadata.fields.map(field => renderField(field)));

		const renderCommunicationsContactForm = () =>
			renderForm(node.formMetadata.fields.map(field => renderField(field)));

		const renderInternalMarketComplaintForm = () =>
			this.state.acceptedDisclaimer ? (
				renderForm(node.formMetadata.fields.map(field => renderField(field)))
			) : (
				<form
					onSubmit={e => {
						e.preventDefault();
						this.setState({ acceptedDisclaimer: true });
					}}
				>
					<div
						dangerouslySetInnerHTML={{
							__html: t("Form.internal_market_complaint.disclaimer.text_html")
						}}
					/>
					<div className="field-type-checkbox disclaimer">
						<input id="accept-disclaimer" type="checkbox" required />
						<label
							htmlFor="accept-disclaimer"
							dangerouslySetInnerHTML={{
								__html: t("Form.internal_market_complaint.disclaimer.checkbox_html")
							}}
						/>
					</div>
					<button className="button small" type="submit">
						{t("Form.internal_market_complaint.disclaimer.continue")}
					</button>
				</form>
			);

		return (
			<Fragment>
				<h1>{node.title}</h1>
				{node.body && <ClickableDrupalContent content={node.body} />}
				{node.formId === "contact_us" && renderContactUsForm()}
				{node.formId === "communications_contact" && renderCommunicationsContactForm()}
				{node.formId === "internal_market_complaint" && renderInternalMarketComplaintForm()}
			</Fragment>
		);
	}
}
